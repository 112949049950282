<template>
  <div>
    <v-container
      fluid
      id="scroll-target"
      style="max-height: 500px"
      class="overflow-y-auto"
    >
      <div v-if="countCart === 0">
        <v-alert outlined color="warning" icon="mdi-cart-off">
          Empty shopping cart!
        </v-alert>
      </div>
      <v-simple-table v-if="countCart > 0" v-scroll:#scroll-target="onScroll">
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">Logo</th>
              <th class="text-left">Name</th>
              <th class="text-left">Price (weight)</th>
              <th class="text-left">Action</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in carts" :key="'cart' + index">
              <td style="width: 10%">
                <v-list-item-avatar style="width: 1px">
                  <v-img :src="getImage('/' + item.cover)"></v-img>
                </v-list-item-avatar>
              </td>
              <td style="width: 30%">
                {{ item.title }}
              </td>

              <td style="width: 30%">
                {{ item.price }} ({{ item.weight }}gr)
                <p
                  v-if="
                    item.promotion_id != null &&
                    item.typ_promotion_id == 2 &&
                    item.quantity >= item.required
                  "
                >
                  {{ item.result }}%
                </p>
              </td>
              <td style="width: 30%">
                <v-btn icon small rounded depressed @click.stop="removeCart(item)">
                  <v-icon dark color="error">mdi-minus-circle</v-icon>
                </v-btn>
                {{ item.quantity }}
                <v-btn icon small rounded depressed @click.stop="addCart(item)">
                  <v-icon dark color="success">mdi-plus-circle</v-icon>
                </v-btn>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-container>
    <v-card-text>
      <v-layout wrap>
        <v-flex pa-1 xs6>
          Total Price ({{ totalQuantity }} items)<br />
          <span class="title">Rp. {{ totalPrice.toLocaleString("id-ID") }}</span>
        </v-flex>
        <v-flex pa-1 xs6 text-right v-if="!guest">
          <div v-if="countCart != 0">
            <v-btn
              color="#1b5e20"
              @click="checkout"
              :disabled="totalQuantity == 0"
              class="white--text"
            >
              <v-icon color="white">mdi-cart-arrow-right</v-icon> &nbsp; Checkout
            </v-btn>
          </div>
        </v-flex>
      </v-layout>
    </v-card-text>
    <div class="text-center">
      <v-dialog v-model="dialogInvoice" max-width="0">
        <v-card color="transparent">
          <v-container>
            <div id="InvPckPrint">
              <div id="invoice-POS">
                <center id="top">
                  <img
                    :src="getImage('/' + setting.logo_path_url)"
                    height="50px"
                    width="50px"
                    contain
                    v-if="!guest"
                    class="text-center"
                  />
                  <div>
                    <h2>{{ setting.store }}</h2>
                  </div>
                </center>

                <div id="mid">
                  <div>
                    <p class="text-left">
                      {{ timestamp }} wib<br />
                      Cashier :{{ user.name }}<br />
                      Address : {{ setting.location_store }}<br />
                      Contact : {{ setting.contact }}<br />
                      Code :{{ orders.invoice_number }}
                    </p>
                  </div>
                </div>

                <div id="bot">
                  <div id="table">
                    <table>
                      <tr class="tabletitle">
                        <td class="item">
                          <h2>Item</h2>
                        </td>
                        <td class="Hours">
                          <h2>Qty</h2>
                        </td>
                        <td class="Rate">
                          <h2>Sub Total</h2>
                        </td>
                      </tr>
                      <tr
                        v-for="(item, index) in product_order"
                        :key="'product_order' + index"
                        class="service"
                      >
                        <td class="tableitem">
                          <p class="itemtext">
                            {{ item.name_product }}
                            <span v-if="item.promotion_name != null">
                              disc:{{ item.promotion_name }}
                            </span>
                          </p>
                        </td>
                        <td class="tableitem">
                          <p class="itemtext">{{ item.quantity }}</p>
                        </td>
                        <td class="tableitem">
                          <p class="itemtext">{{ item.quantity * item.price_product }}</p>
                        </td>
                      </tr>

                      <tr class="tabletitle">
                        <td></td>
                        <td class="Rate">
                          <h2>Tax</h2>
                        </td>
                        <td class="payment">
                          <h2>{{ orders.tax }}</h2>
                        </td>
                      </tr>
                      <tr class="tabletitle" v-if="orders.supplier_sales_tax != 0">
                        <td></td>
                        <td class="Rate">
                          <h2>Supplier Tax</h2>
                        </td>
                        <td class="payment">
                          <h2>{{ orders.supplier_sales_tax }}</h2>
                        </td>
                      </tr>

                      <tr class="tabletitle">
                        <td></td>
                        <td class="Rate">
                          <h2>Total</h2>
                        </td>
                        <td class="payment">
                          <h2>{{ orders.total_bill }}</h2>
                        </td>
                      </tr>
                    </table>
                  </div>

                  <div id="legalcopy">
                    <p class="legal">
                      <strong>Additional Notes**</strong><br />
                      Items that have been purchased cannot be returned.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </v-container></v-card
        >
      </v-dialog>
      <v-dialog v-model="dialogNominal" max-width="500">
        <v-card color="#1b5e20">
          <v-container>
            <v-layout row wrap>
              <v-flex xs6 text-center>
                <v-card-title>TOTAL RETURN</v-card-title>
              </v-flex>
              <v-flex xs6 text-center>
                <v-card-title v-if="nominalCash == null">NOT RETURN</v-card-title>
                <v-card-title v-else
                  >Rp.
                  {{
                    nominalCash - (totalTemp + Number.parseInt(setting.tax))
                  }}</v-card-title
                >
              </v-flex>
            </v-layout>
            <v-layout row wrap>
              <v-flex xs6 text-center>
                <v-card-title>PRINT INVOICES</v-card-title>
              </v-flex>
            </v-layout>
            <v-card-actions>
              <v-btn color="white" @click="succesPay()">
                <v-text-title class="green--text">Cancle</v-text-title>
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn color="white" @click="print()">
                <v-text-title class="green--text">OK</v-text-title>
              </v-btn>
            </v-card-actions>
          </v-container>
        </v-card>
      </v-dialog>
    </div>

    <div class="text-center">
      <v-dialog v-model="dialogCheckout" class="blue---text" max-height="500">
        <v-overlay :absolute="absolute" :value="overlay">
          <v-row align="center" justify="center">
            <v-overlay color="White" :absolute="absolute" :value="progress">
              <div class="text-center">
                <v-progress-circular :size="70" :width="7" color="blue" indeterminate>
                </v-progress-circular>
              </div>
            </v-overlay>
          </v-row>
          <v-card color="white" style="height: auto; width: 350px">
            <v-container style="height: auto">
              <h1><span class="black--text ml-0 caption"> Payment Method </span></h1>
              <br />
              <v-row justify="center">
                <v-col cols="12" sm="6">
                  <h1><span class="black--text ml-0 caption">Total Bill</span></h1>
                </v-col>
                <v-col cols="12" sm="6">
                  <h1>
                    <span class="black--text ml-0 caption"
                      >Rp.
                      {{
                        (totalPrice + Number.parseInt(this.setting.tax)).toLocaleString(
                          "id-ID"
                        )
                      }}</span
                    >
                  </h1>
                </v-col>
              </v-row>

              <v-item-group mandatory>
                <v-row justify="center">
                  <v-col cols="12" sm="6">
                    <v-item v-slot="{ active, toggle }">
                      <v-card
                        color="green"
                        class="d-flex align-center rounded-lg caption"
                        dark
                        outlined
                        @click="toggle, cash()"
                        flat
                      >
                        <v-list-item three-line class="text-center mt-0">
                          <v-list-item-content>
                            <div>
                              <v-icon :color="active ? 'white' : 'white'">
                                fas fa-money-bill-wave
                              </v-icon>
                            </div>
                            <v-list-item-subtitle
                              :class="active ? 'white--text' : 'white--text'"
                              class="mt-n2 caption"
                              >Cash</v-list-item-subtitle
                            >
                          </v-list-item-content>
                        </v-list-item>
                      </v-card>
                    </v-item>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-item v-slot="{ active, toggle }">
                      <v-card
                        color="green"
                        class="d-flex align-center rounded-lg"
                        dark
                        outlined
                        @click="toggle, card()"
                        flat
                      >
                        <v-list-item three-line class="text-center mt-0">
                          <v-list-item-content>
                            <div>
                              <v-icon :color="active ? 'white' : 'white'">
                                fas fa-credit-card
                              </v-icon>
                            </div>
                            <v-list-item-subtitle
                              :class="active ? 'white--text' : 'white--text'"
                              class="mt-n2 caption"
                              >Debit Card
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </v-card>
                    </v-item>
                  </v-col>
                </v-row>
              </v-item-group>
              <v-text-field
                v-model="nominalCash"
                type="number"
                required
                label="Nominal Cash"
                :disabled="isCash"
                :rules="nominalCashRule"
                class="my-text-style"
              ></v-text-field>

              <v-select
                label="Select Market Place"
                required
                :items="platform_market"
                v-model="numberCard"
                item-text="name"
                item-value="name"
                class="my-text-style"
                :disabled="isCard"
                :rules="debitCashRule"
              ></v-select>

              <v-text-field
                v-model="taxStore"
                label="Tax"
                type="number"
                required
                class="my-text-style"
                disabled
              ></v-text-field>
              <v-text-field
                v-model="tax"
                label="Suppliers Tax"
                type="number"
                required
                :rules="taxRule"
                class="my-text-style"
              ></v-text-field>

              <v-card-actions>
                <v-btn
                  width="100"
                  color="#1b5e20"
                  @click="(overlay = false), (dialogCheckout = false)"
                >
                  <v-text-title class="white--text caption">Cancel</v-text-title>
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn color="#1b5e20" @click="pay" width="100">
                  <v-text-title class="white--text caption">PAY</v-text-title>
                </v-btn>
              </v-card-actions>
            </v-container>
          </v-card>
        </v-overlay>
      </v-dialog>
    </div>
    <div class="text-center">
      <v-dialog v-model="dialogClear" max-width="200">
        <v-btn color="info" @click="refreshData">
          <v-text-title class="white--text">REFRESH DATA</v-text-title>
        </v-btn>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  data: () => ({
    absolute: true,
    overlay: false,
    timestamp: "",
    dialogCheckout: false,
    isCash: true,
    isCard: true,
    nominalCashRule: [
      (v) => !!v,
      (v) => /([0-9])/.test(v) || "Nominal Cash must be valid",
    ],
    debitCashRule: [(v) => !!v],
    numberCard: "",
    nominalCash: "",
    tax: 0,
    taxRule: [(v) => /([0-9])/.test(v) || "Tax must be valid"],
    progress: false,
    dialogNominal: false,
    totalTemp: "",
    orderId: "",
    orders: "",
    product_order: [],
    categories: [],
    dialogClear: false,
    dialogInvoice: false,
    taxStore: "",
    platform_market: [],
  }),
  computed: {
    ...mapGetters({
      carts: "cart/carts",
      countCart: "cart/count",
      totalPrice: "cart/totalPrice",
      totalQuantity: "cart/totalQuantity",
      totalWeight: "cart/totalWeight",
      totalStock: "cart/totalStock",
      user: "auth/user",
      guest: "auth/guest",
      setting: "setting/setting",
      flatform: "flatform/flatform",
    }),
  },
  created() {
    setInterval(this.getNow, 1000);
    this.display = "display:none";
    this.getComboBox();
  },
  methods: {
    ...mapActions({
      setAlert: "alert/set",
      addCart: "cart/add",
      removeCart: "cart/remove",
      setCart: "cart/set",
      setAuth: "auth/set",
      setSetting: "setting/set",
      setFlatform: "flatform/set",
    }),
    cash() {
      this.isCard = true;
      this.isCash = false;
      this.numberCard = null;
      this.nominalCash = null;
      this.tax = 0;
    },
    card() {
      this.isCash = true;
      this.isCard = false;
      this.nominalCash = null;
      this.numberCard = null;
      this.tax = 0;
    },
    checkout() {
      this.taxStore = this.setting.tax;
      this.dialogCheckout = true;
      this.overlay = true;
      // this.close()
    },
    close() {
      this.$emit("closed", false);
    },

    print() {
      let divToPrint = document.getElementById("InvPckPrint");
      let htmlToPrint =
        '<style type="text/css">' +
        "	#invoice-POS {	" +
        "	box-shadow: 0 0 1in -0.25in rgba(0, 0, 0, 0);	" +
        "	padding: 2mm;	" +
        "	margin: 0 auto;	" +
        "	width: 44mm;	" +
        "	background: #FFF;	" +
        "	}	" +
        "	::selection {	" +
        "	background: #f31544;	" +
        "	color: #FFF;	" +
        "	}	" +
        "	::moz-selection {	" +
        "	background: #f31544;	" +
        "	color: #FFF;	" +
        "	}	" +
        "	h1 {	" +
        "	font-size: 1.5em;	" +
        "	color: rgb(0, 0, 0);	" +
        "	}	" +
        "	h2 {	" +
        "	font-size: .9em;	" +
        "	}	" +
        "	h3 {	" +
        "	font-size: 1.2em;	" +
        "	font-weight: 300;	" +
        "	line-height: 2em;	" +
        "	}	" +
        "	p {	" +
        "	font-size: .7em;	" +
        "	color: #666;	" +
        "	line-height: 1.2em;	" +
        "	}	" +
        "	#top,	" +
        "	#mid,	" +
        "	#bot {	" +
        "	border-bottom: 1px solid #EEE;	" +
        "	}	" +
        "	#top {	" +
        "	min-height: 100px;	" +
        "	}	" +
        "	#mid {	" +
        "	min-height: 80px;	" +
        "	}	" +
        "	#bot {	" +
        "	min-height: 50px;	" +
        "	}	" +
        "	#top .logo {	" +
        "	height: 60px;	" +
        "	width: 60px;	" +
        "	background-size: 60px 60px;	" +
        "	}	" +
        "	.clientlogo {	" +
        "	float: left;	" +
        "	height: 60px;	" +
        "	width: 60px;	" +
        "	background-size: 60px 60px;	" +
        "	border-radius: 50px;	" +
        "	}	" +
        "	.info {	" +
        "	display: block;	" +
        "	margin-left: 0;	" +
        "	}	" +
        "	.title {	" +
        "	float: right;	" +
        "	}	" +
        "	.title p {	" +
        "	text-align: right;	" +
        "	}	" +
        "	table {	" +
        "	width: 100%;	" +
        "	border-collapse: collapse;	" +
        "	}	" +
        "	.tabletitle {	" +
        "	font-size: .5em;	" +
        "	background: #EEE;	" +
        "	}	" +
        "	.service {	" +
        "	border-bottom: 1px solid #EEE;	" +
        "	}	" +
        "	.item {	" +
        "	width: 24mm;	" +
        "	}	" +
        "	.itemtext {	" +
        "	font-size: .5em;	" +
        "	}	" +
        "	#legalcopy {	" +
        "	margin-top: 5mm;	" +
        "	}	" +
        "</style>";

      htmlToPrint += divToPrint.outerHTML;
      let windowToPrint = window.open("");
      windowToPrint.document.write(htmlToPrint);
      windowToPrint.print();
      windowToPrint.close();
      this.succesPay();
    },
    getNow: function () {
      const today = new Date();
      const date =
        today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + today.getDate();
      const time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
      const dateTime = date + " " + time;
      this.timestamp = dateTime;
    },
    pay() {
      this.totalTemp = this.totalPrice;
      let numberCard = this.numberCard;
      let nominalCash = this.nominalCash;
      let tax = this.tax;
      let taxStore = this.taxStore;

      if (!this.isCash && (this.nominalCash == null || this.nominalCash == "")) {
        this.setAlert({
          status: true,
          text: "Nominal Cash is required",
          color: "warning",
        });
        return;
      }
      if (!this.isCard && (this.numberCard == null || this.numberCard == "")) {
        this.setAlert({
          status: true,
          text: "Debit Card is required",
          color: "warning",
        });
        return;
      }
      let pattern = /[0-9]/;
      if (this.tax == 0 || this.tax != "") {
        this.tax = "0";
      }
      if (!this.tax.match(pattern)) {
        this.setAlert({
          status: true,
          text: "Tax must be a number",
          color: "warning",
        });
        return;
      }
      this.progress = true;
      let safeCart = JSON.stringify(this.carts);
      let formData = new FormData();
      formData.set("carts", safeCart);
      formData.set("numberCard", numberCard);
      formData.set("nominalCash", nominalCash);
      formData.set("tax", tax);
      formData.set("taxStore", taxStore);
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.api_token,
        },
      };
      this.axios
        .post("/payment-pos", formData, config)
        .then((response) => {
          let { data } = response;
          this.orderId = data.data.order_id;
          if (data && data.status == "success") {
            this.setCart([]);
            this.goInvoice();
            this.progress = false;
            this.dialogCheckout = false;
            this.dialogInvoice = true;
            this.dialogNominal = true;
          } else {
            this.progress = false;
            this.overlay = false;
            this.setAlert({
              status: true,
              text: data.message,
              color: data.status,
            });
          }
        })
        .catch((error) => {
          this.progress = false;
          let { data } = error.response;
          this.setAlert({
            status: true,
            text: data.message,
            color: "error",
          });
        });
    },
    succesPay() {
      this.dialogNominal = false;
      this.overlay = false;
      this.dialogCheckout = false;
      this.dialogInvoice = false;
      this.$router.push({ path: "/pages/point-of-sale" });
    },
    refreshData() {
      window.location.reload();
    },
    goInvoice() {
      this.orders = null;
      this.product_order = null;
      let formData = new FormData();
      formData.set("id", this.orderId);
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.api_token,
        },
      };
      let url = "/orders/report-order";
      this.axios
        .post(url, formData, config)
        .then((response) => {
          let { data } = response.data;
          this.orders = data;
          this.product_order = response.data.product_order;
        })
        .catch((error) => {
          let { responses } = error;
          console.log(responses);
        });
    },
    getCategory() {
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.api_token,
        },
      };
      this.axios
        .get("/categories/random/100", config)
        .then((response) => {
          let { data } = response.data;
          this.categories = data;
        })
        .catch((error) => {
          let { responses } = error;
          console.log(responses);
        });
    },
    getComboBox() {
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.api_token,
        },
      };
      this.axios
        .get("/purchase/platform-market-combobox", config)
        .then((response) => {
          let { data } = response.data;
          this.platform_market = data;
        })
        .catch((error) => {
          let { responses } = error;
          console.log(responses);
        });
    },
  },
};
</script>

<style>
#invoice-POS {
  box-shadow: 0 0 1in -0.25in rgba(0, 0, 0, 0);
  padding: 2mm;
  margin: 0 auto;
  width: 44mm;
  background: #fff;
}

::selection {
  background: #f31544;
  color: #fff;
}

::moz-selection {
  background: #f31544;
  color: #fff;
}

h1 {
  font-size: 1.5em;
  color: rgb(0, 0, 0);
}

h2 {
  font-size: 0.9em;
}

h3 {
  font-size: 1.2em;
  font-weight: 300;
  line-height: 2em;
}

p {
  font-size: 0.7em;
  color: #666;
  line-height: 1.2em;
}

#top,
#mid,
#bot {
  border-bottom: 1px solid #eee;
}

#top {
  min-height: 100px;
}

#mid {
  min-height: 80px;
}

#bot {
  min-height: 50px;
}

#top .logo {
  height: 60px;
  width: 60px;
  background-size: 60px 60px;
}

.clientlogo {
  float: left;
  height: 60px;
  width: 60px;
  background-size: 60px 60px;
  border-radius: 50px;
}

.info {
  display: block;
  margin-left: 0;
}

.title {
  float: right;
}

.title p {
  text-align: right;
}

table {
  width: 100%;
  border-collapse: collapse;
}

.tabletitle {
  font-size: 0.5em;
  background: #eee;
}

.service {
  border-bottom: 1px solid #eee;
}

.item {
  width: 24mm;
}

.itemtext {
  font-size: 0.5em;
}

#legalcopy {
  margin-top: 5mm;
}

.my-text-style {
  -webkit-text-fill-color: black;
  width: 100%;
  padding: 0px 0px;
  margin: 0px 0px;
  border: none;
  background-color: #f2f8f6;
}
</style>
