<template>
  <div v-if="promotions != ''">
    <v-toolbar flat>
      <v-toolbar-title>All Promotion</v-toolbar-title>
    </v-toolbar>
    <v-container
      fluid
      id="scroll-target"
      style="max-height: 500px"
      class="overflow-y-auto"
    >
      <div>
        <v-simple-table v-scroll:#scroll-target="onScroll">
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Name</th>
                <th class="text-left">Product</th>
                <th class="text-left">Description</th>
                <th class="text-left">End Date</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in promotions" :key="'promotions' + index">
                <td>
                  {{ item.name }}
                </td>
                <td>
                  {{ item.title }}
                </td>
                <td>
                  {{ item.description }}
                </td>
                <td>
                  {{ item.end_date }}
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <div class="text-center">
          <v-pagination
            v-model="page"
            @input="go"
            :length="lengthPage"
            :total-visible="15"
            color="#1b5e20"
          ></v-pagination>
        </div>
      </div>
    </v-container>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";

export default {
  data() {
    return {
      promotions: [],
      keyword: "",
      page: 0,
      lengthPage: 0,
      headers: [
        { text: "Promotion", value: "name" },
        { text: "Product", value: "title" },
        { text: "Description", value: "description" },
        { text: "End Date", value: "end_date" },
      ],
    };
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
  },

  methods: {
    ...mapActions({
      setAlert: "alert/set",
      setAuth: "auth/set",
    }),
    go() {
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.api_token,
        },
      };
      let url = "/promotion-pos?page=" + this.page;
      this.axios
        .get(url, config)
        .then((response) => {
          let { data } = response.data;
          let { meta } = response.data;
          this.promotions = data;
          this.lengthPage = meta.last_page;
          this.page = meta.current_page;
        })
        .catch((error) => {
          let { responses } = error;
          console.log(responses);
        });
    },
  },

  created() {
    this.go();
  },
};
</script>
